import { ReactNode, createContext, useContext, useMemo } from 'react';

import { useService } from 'context/Service';
import { AirtableID, extractGenericOfReactContext } from 'context/utils';

import { IBook, useBooks } from './Books';
import { useCustomBooks } from './CustomBooks';

export interface IFairPack {
    id: string;
    fair: string;

    books: IBook[];
}

const FairPackContext = createContext<[IFairPack[]] | undefined>(undefined);

interface IFairPackProps {
    children: ReactNode;
}

export type FairPackApi = {
    Fair: string;

    Books: AirtableID;

    'Custom books': AirtableID;
};

export function FairPackProvider({ children }: IFairPackProps): JSX.Element {
    const [books] = useBooks();
    const [customBooks] = useCustomBooks();
    const { apiFairPack } = useService();

    const fairPacks = useMemo(
        (): IFairPack[] =>
            apiFairPack.records
                .filter(
                    (
                        record,
                    ): record is {
                        id: string;
                        fields: FairPackApi;
                    } =>
                        !!record.fields.Fair &&
                        (!!record.fields.Books?.length ||
                            !!record.fields['Custom books']?.length),
                )
                .map((item) => ({
                    id: item.id,
                    fair: item.fields.Fair,
                    books: [
                        ...books.filter((book) =>
                            (item.fields.Books ?? []).includes(book.id),
                        ),
                        ...customBooks.filter((customBook) =>
                            (item.fields['Custom books'] ?? []).includes(
                                customBook.id,
                            ),
                        ),
                    ],
                })),
        [],
    );

    return (
        <FairPackContext.Provider value={[fairPacks]}>
            {children}
        </FairPackContext.Provider>
    );
}

export function useFairPack(): NonNullable<
    extractGenericOfReactContext<typeof FairPackContext>
> {
    const context = useContext(FairPackContext);
    if (context === undefined) {
        throw new Error('useFairPack must be within a FairPackProvider');
    }
    return context;
}
