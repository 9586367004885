import { ReactNode, createContext, useContext, useMemo } from 'react';

import { useService } from 'context/Service';
import { AirtableID, extractGenericOfReactContext } from 'context/utils';

import { IBook, useBooks } from './Books';
import { useCustomBooks } from './CustomBooks';

export interface ISchoolPack {
    id: string;
    school: string;

    books: IBook[];
}

const SchoolPackContext = createContext<[ISchoolPack[]] | undefined>(undefined);

interface ISchoolPackProps {
    children: ReactNode;
}

export type SchoolPackApi = {
    School: string;

    Books: AirtableID;

    'Custom books': AirtableID;
};

export function SchoolPackProvider({
    children,
}: ISchoolPackProps): JSX.Element {
    const [books] = useBooks();
    const [customBooks] = useCustomBooks();
    const { apiSchoolPack } = useService();

    const schoolPacks = useMemo(
        (): ISchoolPack[] =>
            apiSchoolPack.records
                .filter(
                    (
                        record,
                    ): record is {
                        id: string;
                        fields: SchoolPackApi;
                    } =>
                        !!record.fields.School &&
                        (!!record.fields.Books?.length ||
                            !!record.fields['Custom books']?.length),
                )
                .map((item) => ({
                    id: item.id,
                    school: item.fields.School,
                    books: [
                        ...books.filter((book) =>
                            (item.fields.Books ?? []).includes(book.id),
                        ),
                        ...customBooks.filter((customBook) =>
                            (item.fields['Custom books'] ?? []).includes(
                                customBook.id,
                            ),
                        ),
                    ],
                })),
        [],
    );

    return (
        <SchoolPackContext.Provider value={[schoolPacks]}>
            {children}
        </SchoolPackContext.Provider>
    );
}

export function useSchoolPack(): NonNullable<
    extractGenericOfReactContext<typeof SchoolPackContext>
> {
    const context = useContext(SchoolPackContext);
    if (context === undefined) {
        throw new Error('useSchoolPack must be within a SchoolPackProvider');
    }
    return context;
}
