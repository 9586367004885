import preval from 'preval.macro';
import { QueryClient } from 'react-query';
import { createLocalStoragePersistor } from 'react-query/createLocalStoragePersistor-experimental';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 1000 * 60 * 15, // 15 minutes
        },
    },
});

const localStoragePersistor = createLocalStoragePersistor({
    throttleTime: 1, // Solved non consistent cache serialization
});

const buildTimestamp = preval`module.exports = new Date().toLocaleString()`;

persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
    buster: process.env.NODE_ENV === 'production' ? buildTimestamp : '', // this removes old cache from previous deployments
    maxAge: 1000 * 60 * 15, // 15 minutes
});

export default queryClient;
