import { Autocomplete, TextField } from '@mui/material';
import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Button, Modal } from 'react-bootstrap';

import { useEmployees } from './Employees';
import { useLocalUsername } from './LocalUsername';
import { extractGenericOfReactContext } from './utils';

type onChoose = (newUsername: string) => void;

type chooseUsername = (props?: {
    force?: boolean;
    onChoose?: onChoose;
}) => void;

const ChooseUsername = createContext<chooseUsername | undefined>(undefined);

export function ChooseUsernameProvider({
    children,
}: {
    children: ReactNode;
}): JSX.Element {
    const [show, setShow] = useState(false);
    const [autocompleteValue, setAutocompleteValue] = useState('');

    const [employees] = useEmployees();
    const [localUsername, setLocalUsername] = useLocalUsername();
    const [force, setForce] = useState(false);

    const onChooseFuncRef = useRef<onChoose | null>(null);

    const onHide = (): void => {
        setShow(false);
    };

    const chooseUsername: chooseUsername = (props = {}) => {
        setShow(true);
        setForce(props.force ?? false);

        onChooseFuncRef.current = props.onChoose ?? null;
    };

    const onChooseUsername = (newInputValue: string): void => {
        setLocalUsername(newInputValue);
        onChooseFuncRef.current?.(newInputValue);

        onHide();
    };

    useEffect(() => {
        setAutocompleteValue(localUsername);
    }, [localUsername]);

    return (
        <ChooseUsername.Provider value={chooseUsername}>
            {children}

            <Modal
                show={show}
                size="sm"
                centered
                onHide={onHide}
                backdrop={force ? 'static' : undefined}
                keyboard={false}
            >
                <Modal.Body>
                    <div className="d-flex flex-row justify-content-center">
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            options={employees.map((employee) => employee.name)}
                            sx={{ width: 300 }}
                            onChange={(_, newInputValue): void => {
                                if (newInputValue) {
                                    onChooseUsername(newInputValue);
                                }
                            }}
                            value={autocompleteValue}
                            renderInput={(params): JSX.Element => (
                                <TextField {...params} label="Usuario" />
                            )}
                        />
                    </div>
                    <div className="d-flex flex-row justify-content-center mt-4">
                        <Button
                            variant="outline-primary"
                            className="w-100"
                            onClick={(): void =>
                                onChooseUsername(localUsername)
                            }
                        >
                            Continuar como {localUsername}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </ChooseUsername.Provider>
    );
}

export function useChooseUsername(): NonNullable<
    extractGenericOfReactContext<typeof ChooseUsername>
> {
    const context = useContext(ChooseUsername);
    if (context === undefined) {
        throw new Error(
            'useChooseUsername must be within a ChooseUsernameProvider',
        );
    }
    return context;
}
