import { ReactElement, ReactNode, createContext, useContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import { firebase } from 'services/firebase';

import { extractGenericOfReactContext } from './utils';

interface ILoginProps {
    username: string;
    password: string;
}

const AuthContext = createContext<
    | {
          data: { user: unknown };
          login: ({ username, password }: ILoginProps) => Promise<unknown>;
          logout: () => Promise<void>;
      }
    | undefined
>(undefined);

interface IAuthProps {
    whileLoading?: ReactElement;
    children: ReactNode;
}

export function AuthProvider({
    whileLoading,
    children,
}: IAuthProps): JSX.Element {
    const [user, loading, error] = useAuthState(firebase.auth());

    if (loading) {
        return whileLoading ? whileLoading : <span>loading...</span>;
    }

    if (error) {
        return <span>error: ${error}</span>;
    }

    const login = ({ username, password }: ILoginProps): Promise<unknown> => {
        return firebase
            .auth()
            .signInWithEmailAndPassword(`${username}@gruponoral.com`, password);
    };

    const logout = async (): Promise<void> => {
        await firebase.auth().signOut();
    };

    return (
        <AuthContext.Provider value={{ data: { user }, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth(): NonNullable<
    extractGenericOfReactContext<typeof AuthContext>
> {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be within a AuthProvider');
    }
    return context;
}
