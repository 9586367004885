import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RecoilRoot } from 'recoil';

import App from './App';
import GlobalStyles from './globalStyles';
import reportWebVitals from './reportWebVitals';
import queryClient from './utils/persistent-query-client';

ReactGA.initialize('UA-160924990-3', {
    testMode: process.env.NODE_ENV !== 'production',
});
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <GlobalStyles />
            <QueryClientProvider client={queryClient}>
                <App />
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </QueryClientProvider>
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root'),
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const sendToAnalytics = ({ name, value }): void => {
    ReactGA.timing({
        category: 'Web Vitals',
        variable: name,
        value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
    });
};

reportWebVitals(sendToAnalytics);
