import axios from 'axios';
import React, { ReactNode, createContext, useContext } from 'react';
import { useQuery } from 'react-query';

import { Loader } from 'components/catalogs/school-catalog/Loader';

import { BookApi } from './Books';
import { CustomBookApi } from './CustomBooks';
import { EmployeeApi } from './Employees';
import { FairPackApi } from './Fair packs';
import { PublisherApi } from './Publishers';
import { SchoolPackApi } from './SchoolPack';
import { NoCodeApiGetAllResponse, extractGenericOfReactContext } from './utils';

type ApiResponse<T> = NoCodeApiGetAllResponse<Partial<T>>;

type ContextValues = {
    apiPublishers: ApiResponse<PublisherApi>;
    apiBooks: ApiResponse<BookApi>;
    apiCustomBooks: ApiResponse<CustomBookApi>;
    apiSchoolPack: ApiResponse<SchoolPackApi>;
    apiFairPack: ApiResponse<FairPackApi>;
    apiEmployees: ApiResponse<EmployeeApi>;
};

const apiUrls: Record<keyof ContextValues, string> = {
    apiPublishers:
        'https://v1.nocodeapi.com/oscaramosr/airtable/PFDKWoxywndxkKpi?tableName=Publishers&perPage=all',
    apiBooks:
        'https://v1.nocodeapi.com/oscaramosr/airtable/PFDKWoxywndxkKpi?tableName=Books&perPage=all',
    apiCustomBooks:
        'https://v1.nocodeapi.com/oscaramosr/airtable/PFDKWoxywndxkKpi?tableName=Custom books&perPage=all',
    apiSchoolPack:
        'https://v1.nocodeapi.com/oscaramosr/airtable/PFDKWoxywndxkKpi?tableName=School packs&perPage=all',
    apiFairPack:
        'https://v1.nocodeapi.com/oscaramosr/airtable/PFDKWoxywndxkKpi?tableName=Fair packs&perPage=all',
    apiEmployees:
        'https://v1.nocodeapi.com/oscaramosr/airtable/PFDKWoxywndxkKpi?tableName=Employees&perPage=all',
};

const ServiceContext = createContext<ContextValues | undefined>(undefined);

export function ServiceProvider({
    children,
}: {
    children: ReactNode;
}): JSX.Element {
    const { error, data } = useQuery<ContextValues>(
        'apiData',
        async () => {
            const promisedValuesData = (
                await Promise.all(
                    Object.values(apiUrls).map((url) => axios.get(url)),
                )
            ).map((value) => value.data);

            return Object.fromEntries(
                Object.entries(apiUrls).map(([key], index) => [
                    key,
                    promisedValuesData[index],
                ]),
            ) as ContextValues;
        },
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    );

    if (error) {
        throw new Error((error as Error).message);
    }

    if (!data) {
        return <Loader />;
    }

    return (
        <ServiceContext.Provider value={data}>
            {children}
        </ServiceContext.Provider>
    );
}

export function useService(): NonNullable<
    extractGenericOfReactContext<typeof ServiceContext>
> {
    const context = useContext(ServiceContext);
    if (context === undefined) {
        throw new Error('useService must be within a ServiceProvider');
    }
    return context;
}
