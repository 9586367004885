import styled from 'styled-components';

import { ReactComponent as LoaderSvg } from '../../../assets/loader.svg';

export const Loader = styled(LoaderSvg)`
    width: 100px;
    height: 100px;

    background-color: var(--background) !important;
`;
