import { SetterOrUpdater, atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

const localUsernameAtom = atom<string>({
    key: 'localUsername',
    default: '',
    effects_UNSTABLE: [persistAtom],
});

export function useLocalUsername(): [
    localUsername: string,
    setLocalUsername: SetterOrUpdater<string>,
] {
    return useRecoilState(localUsernameAtom);
}
