import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useMemo,
} from 'react';

import { useService } from './Service';
import { extractGenericOfReactContext } from './utils';

export interface IEmployee {
    id: string;
    name: string;
}

const Employees = createContext<
    | [IEmployee[], { getEmployee: (id: string) => IEmployee | undefined }]
    | undefined
>(undefined);

interface IEmployeesProps {
    children: ReactNode;
}

export type EmployeeApi = {
    Name: string;
};

export function EmployeesProvider({ children }: IEmployeesProps): JSX.Element {
    const { apiEmployees } = useService();

    const employees = useMemo(
        () =>
            apiEmployees.records
                .filter(
                    (
                        record,
                    ): record is {
                        id: string;
                        fields: EmployeeApi;
                    } => !!record.fields.Name,
                )
                .map(({ id, fields }) => ({
                    id: id,
                    name: fields.Name,
                })),
        [],
    );

    const getEmployee = useCallback(
        (id: string): IEmployee | undefined =>
            employees?.find((employee) => employee.id === id),
        [employees],
    );

    return (
        <Employees.Provider value={[employees, { getEmployee }]}>
            {children}
        </Employees.Provider>
    );
}

export function useEmployees(): NonNullable<
    extractGenericOfReactContext<typeof Employees>
> {
    const context = useContext(Employees);
    if (context === undefined) {
        throw new Error('useEmployees must be within a EmployeesProvider');
    }
    return context;
}
