import React, { ReactNode, Suspense, lazy, useEffect } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import ReactGA from 'react-ga';
import styled, { ThemeProvider } from 'styled-components';
import { Redirect, Route, Switch } from 'wouter';

import { BooksProvider } from 'context/Books';
import { ChooseUsernameProvider } from 'context/ChooseUsername';
import { EmployeesProvider } from 'context/Employees';
import { GradesProvider } from 'context/Grades';
import { PublishersProvider } from 'context/Publishers';
import { SchoolPackProvider } from 'context/SchoolPack';
import { ServiceProvider } from 'context/Service';
import { AuthProvider } from 'context/auth-context';
import { VariantProvider } from 'context/useVariant';
import { UserProvider, useUser } from 'context/user-context';

import LoaderGif from 'assets/loader.gif';

import { CustomBooksProvider } from './context/CustomBooks';
import { FairPackProvider } from './context/Fair packs';
import { theme } from './theme';

const Home = lazy(() => import('./pages/home/Home'));
const Login = lazy(() => import('./pages/login/Login'));
const SchoolPack = lazy(() => import('./pages/school-pack/SchoolPackPage'));

function InternalRoutes(): JSX.Element {
    const user = useUser();

    return (
        <Switch>
            <Route path="/login">
                {user ? <Redirect to="/school-catalog" /> : <Login />}
            </Route>
            <Route path="/school-catalog">
                {user ? <SchoolPack /> : <Redirect to="/login" />}
            </Route>
        </Switch>
    );
}

const FullPageLoader = styled.div`
    background-color: var(--background);
    width: 100vw;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;
`;

function ApiProviders({ children }: { children: ReactNode }): JSX.Element {
    return (
        <ServiceProvider>
            <PublishersProvider>
                <GradesProvider>
                    <BooksProvider>
                        <CustomBooksProvider>
                            <SchoolPackProvider>
                                <FairPackProvider>
                                    <EmployeesProvider>
                                        {children}
                                    </EmployeesProvider>
                                </FairPackProvider>
                            </SchoolPackProvider>
                        </CustomBooksProvider>
                    </BooksProvider>
                </GradesProvider>
            </PublishersProvider>
        </ServiceProvider>
    );
}

function InternalRoutesContainer(): JSX.Element {
    return (
        <ApiProviders>
            <AuthProvider
                whileLoading={
                    <FullPageLoader>
                        <img src={LoaderGif} alt="" />
                    </FullPageLoader>
                }
            >
                <UserProvider>
                    <ChooseUsernameProvider>
                        <VariantProvider variant="employee">
                            <InternalRoutes />
                        </VariantProvider>
                    </ChooseUsernameProvider>
                </UserProvider>
            </AuthProvider>
        </ApiProviders>
    );
}

function ErrorFallback({
    error,
    resetErrorBoundary,
}: FallbackProps): JSX.Element {
    useEffect(() => {
        ReactGA.exception({
            description: error.message,
            fatal: true,
        });
    }, [error.message]);

    return (
        <div role="alert">
            <h6>Algo anda mal</h6>
            <p className="d-inline">Mensaje de error:</p>
            <pre style={{ color: 'red' }} className="d-inline ms-2">
                {error.message}
            </pre>
            <div className="mt-2">
                <button onClick={resetErrorBoundary}>Reiniciar página</button>
            </div>
        </div>
    );
}

function App(): JSX.Element {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ThemeProvider theme={theme}>
                <Suspense
                    fallback={
                        <FullPageLoader>
                            <img src={LoaderGif} alt="" />
                        </FullPageLoader>
                    }
                >
                    <Switch>
                        <Route path="/" component={Home} />
                        <Route>
                            <InternalRoutesContainer />
                        </Route>
                    </Switch>
                </Suspense>
            </ThemeProvider>
        </ErrorBoundary>
    );
}

export default App;
