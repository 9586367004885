import { ReactNode, createContext, useContext } from 'react';

import { extractGenericOfReactContext } from './utils';

type Variant = 'public' | 'employee';

const VariantContext = createContext<Variant | undefined>(undefined);

interface IVariantProviderProps {
    variant: Variant;
    children: ReactNode;
}

export function VariantProvider({
    variant,
    children,
}: IVariantProviderProps): JSX.Element {
    return (
        <VariantContext.Provider value={variant}>
            {children}
        </VariantContext.Provider>
    );
}

export function useVariant(): NonNullable<
    extractGenericOfReactContext<typeof VariantContext>
> {
    const context = useContext(VariantContext);
    if (context === undefined) {
        throw new Error('useVariant must be within a VariantProvider');
    }
    return context;
}
