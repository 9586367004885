import { ReactNode, createContext, useContext } from 'react';

import { useAuth } from './auth-context';
import { extractGenericOfReactContext } from './utils';

const UserContext = createContext<unknown | undefined>(undefined);

export const UserProvider = ({
    children,
}: {
    children: ReactNode;
}): JSX.Element => (
    <UserContext.Provider value={useAuth()?.data.user}>
        {children}
    </UserContext.Provider>
);

export const useUser = (): extractGenericOfReactContext<typeof UserContext> =>
    useContext(UserContext);
