import { useState } from 'react';

function useSelectedItems<T extends { id: string }>(
    items: T[],
): {
    items: T[];
    selectAll: () => void;
    removeAll: () => void;
    isSelected: (itemId: string) => boolean;
    toggleSelected: (itemId: string) => void;
} {
    const [selectedItems, setSelectedItems] = useState<T[]>([]);

    const selectAll = (): void => setSelectedItems(items);

    const removeAll = (): void => setSelectedItems([]);

    const isSelected = (itemId: string): boolean =>
        !!selectedItems.find((item) => item.id === itemId);

    const toggleSelected = (itemId: string): void =>
        setSelectedItems((prev) =>
            prev.find((item) => item.id === itemId)
                ? prev.filter((item) => item.id !== itemId)
                : [...prev, items?.find((item) => item.id === itemId) as T],
        );

    return {
        items: selectedItems,
        selectAll,
        removeAll,
        isSelected,
        toggleSelected,
    };
}

export default useSelectedItems;
