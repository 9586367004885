import {
    ReactElement,
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useMemo,
} from 'react';

import { IBook, useBooks } from './Books';
import { GradeOptions, LevelOptions, useGrades } from './Grades';
import { useService } from './Service';
import { AirtableID, extractGenericOfReactContext } from './utils';

const CustomBooks = createContext<
    [IBook[], { getBook: (id: string) => IBook | undefined }] | undefined
>(undefined);

interface IBooksProps {
    children: ReactNode;
    whileLoading?: ReactElement;
}

export type CustomBookApi = {
    Title: string;
    Book: [AirtableID];
    Level?: LevelOptions;
    Grade?: GradeOptions;
    Price?: number;
};

export function CustomBooksProvider({ children }: IBooksProps): JSX.Element {
    const { apiCustomBooks } = useService();

    const [books] = useBooks();
    const [, { getGrade }] = useGrades();

    const customBooks = useMemo<IBook[]>(
        () =>
            apiCustomBooks.records
                .filter(
                    (
                        record,
                    ): record is {
                        id: string;
                        fields: CustomBookApi;
                    } =>
                        !!record.fields.Book &&
                        (!!record.fields.Level || !!record.fields.Price),
                )
                .map(({ id, fields }) => {
                    const bookToModify = books.find(
                        (book) => book.id === fields.Book[0],
                    ) as IBook;

                    return {
                        ...bookToModify,
                        id: id,
                        specialGrade: fields.Level
                            ? getGrade(fields.Level, fields.Grade)
                            : undefined,
                        specialPrice: fields.Price,
                    };
                }),
        [],
    );

    const getBook = useCallback(
        (id: string): IBook | undefined =>
            books?.find((book) => book.id === id),
        [books],
    );

    return (
        <CustomBooks.Provider value={[customBooks, { getBook }]}>
            {children}
        </CustomBooks.Provider>
    );
}

export function useCustomBooks(): NonNullable<
    extractGenericOfReactContext<typeof CustomBooks>
> {
    const context = useContext(CustomBooks);
    if (context === undefined) {
        throw new Error('useCustomBooks must be within a CustomBooksProvider');
    }
    return context;
}
