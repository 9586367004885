import _firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const firebaseConfig = {
    apiKey: 'AIzaSyDpNOufMdi_uT_NdBMYiUWZ4O529OqQfVU',
    authDomain: 'noral-website.firebaseapp.com',
    projectId: 'noral-website',
    storageBucket: 'noral-website.appspot.com',
    messagingSenderId: '1029114700020',
    appId: '1:1029114700020:web:f31f2e2d9d00adb441fea1',
};

// Initialize Firebase
_firebase.initializeApp(firebaseConfig);

export const firebase = _firebase;
