import Airtable from 'airtable';
import {
    ReactElement,
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useMemo,
} from 'react';

import { GradeOptions, IGrade, LevelOptions, useGrades } from './Grades';
import { IPublisher, usePublishers } from './Publishers';
import { useService } from './Service';
import { AirtableID, extractGenericOfReactContext } from './utils';

export interface IBook {
    id: string;
    title: string;
    publisher: IPublisher;
    grade: IGrade;
    specialGrade?: IGrade;
    price: number;
    specialPrice?: number;
    image?: [Airtable.Attachment] | [];
    IGV: boolean;
}

const Books = createContext<
    [IBook[], { getBook: (id: string) => IBook | undefined }] | undefined
>(undefined);

interface IBooksProps {
    children: ReactNode;
    whileLoading?: ReactElement;
}

export type BookApi = {
    Title: string;
    Publishers: [AirtableID];
    Level: LevelOptions;
    Grade?: GradeOptions;
    Price: number;
    Image?: [Airtable.Attachment];
    IGV?: boolean;
};

export function BooksProvider({ children }: IBooksProps): JSX.Element {
    const { apiBooks } = useService();

    const [, { getGrade }] = useGrades();
    const [, { getPublisher }] = usePublishers();

    const books = useMemo(
        () =>
            apiBooks.records
                .filter(
                    (
                        record,
                    ): record is {
                        id: string;
                        fields: BookApi;
                    } =>
                        !!record.fields.Title &&
                        !!record.fields.Publishers &&
                        !!record.fields.Level &&
                        !!record.fields.Price,
                )
                .map(({ id, fields }) => ({
                    id: id,
                    title: fields.Title,
                    publisher: getPublisher(fields.Publishers[0]) as IPublisher,
                    grade: getGrade(fields.Level, fields.Grade),
                    price: fields.Price,
                    image: fields.Image,
                    IGV: !!fields.IGV,
                })),
        [],
    );

    const getBook = useCallback(
        (id: string): IBook | undefined =>
            books?.find((book) => book.id === id),
        [books],
    );

    return (
        <Books.Provider value={[books, { getBook }]}>{children}</Books.Provider>
    );
}

export function useBooks(): NonNullable<
    extractGenericOfReactContext<typeof Books>
> {
    const context = useContext(Books);
    if (context === undefined) {
        throw new Error('useBooks must be within a BooksProvider');
    }
    return context;
}
