import Airtable from 'airtable';
import { ReactNode, createContext, useContext, useMemo } from 'react';

import { useService } from './Service';
import useSelectedItems from './useSelectedItems';
import { extractGenericOfReactContext } from './utils';

export interface IPublisher {
    id: string;
    label: string;
    logo: {
        src: string;
        srcSmall: string;
        width: number;
        alt: string;
    };
}

const PublishersContext = createContext<
    | [
          IPublisher[],
          {
              selectedPublishers: IPublisher[];
              selectAll: () => void;
              removeAll: () => void;
              isSelected: (publisherId: string) => boolean;
              toggleSelected: (publisherId: string) => void;
              getPublisher: (publisher: string) => IPublisher | undefined;
          },
      ]
    | undefined
>(undefined);

export type PublisherApi = {
    Name: string;
    Image: [Airtable.Attachment];
    ['Image width']: number;
};

export function PublishersProvider({
    children,
}: {
    children: ReactNode;
}): JSX.Element {
    const { apiPublishers } = useService();

    const publishers = useMemo(
        () =>
            apiPublishers.records
                .filter(
                    (
                        record,
                    ): record is {
                        id: string;
                        fields: PublisherApi;
                    } =>
                        !!record.fields.Name &&
                        !!record.fields.Image?.[0] &&
                        !!record.fields['Image width'],
                )
                .map(({ id, fields }) => ({
                    id,
                    label: fields.Name as string,
                    logo: {
                        src: fields.Image[0].thumbnails?.full.url as string,
                        srcSmall: fields.Image[0].thumbnails?.small
                            .url as string,
                        width: fields['Image width'],
                        alt: `Logo de la publisher ${fields.Name}`,
                    },
                })),
        [apiPublishers],
    );

    const {
        items: selectedPublishers,
        selectAll,
        removeAll,
        isSelected,
        toggleSelected,
    } = useSelectedItems(publishers as IPublisher[]);

    const getPublisher = (id: string): IPublisher | undefined =>
        publishers.find((publisher) => publisher.id === id);

    return (
        <PublishersContext.Provider
            value={[
                publishers,
                {
                    selectedPublishers,
                    selectAll,
                    removeAll,
                    isSelected,
                    toggleSelected,
                    getPublisher,
                },
            ]}
        >
            {children}
        </PublishersContext.Provider>
    );
}

export function usePublishers(): NonNullable<
    extractGenericOfReactContext<typeof PublishersContext>
> {
    const context = useContext(PublishersContext);
    if (context === undefined) {
        throw new Error('usePublishers must be within a PublishersProvider');
    }
    return context;
}
