import { createGlobalStyle } from 'styled-components';

export const white = '#ffffff';
export const wildSand = '#F5F5F5';
export const textColor = '#5b5b5b';
export const ceruleanBlue = '#2352c5';

const GlobalStyle = createGlobalStyle`
  :root {
    --primary-blue: ${ceruleanBlue};
    --background: ${wildSand};
    --background-white: ${white};
    --text-color: ${textColor}
  }

  * {
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: Montserrat, sans-serif;
  }

  h1, h2, button, p {
    color: var(--text-color);
  }

  h1 {
    font-size: 46px;
    font-weight: 700;
  }

  h2 {
    font-size: 30px;
    font-weight: 300;
  }

  p {
    font-weight: 300;
  }

  strong {
    font-weight: bold;
  }
  
  hr {
    opacity: 1;
  }

  .btn {
    font-family: Montserrat, sans-serif;
    font-weight: 300;
    font-size: 12px;

    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .btn-primary {
    color: white;
    background-color: var(--primary-blue);

    :hover {
      background-color: #1B3E98;
    }
  }

  // Little hacks for the zooming
  button[aria-label="Unzoom image"] {
    cursor: zoom-out;
  }

  button[aria-label="Zoom image"] {
    cursor: zoom-in;
  }

  div[data-rmiz-modal-content="true"] {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export default GlobalStyle;
