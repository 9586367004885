import { uniqueId } from 'lodash';
import { ReactNode, createContext, useCallback, useContext } from 'react';

import useSelectedItems from './useSelectedItems';
import { extractGenericOfReactContext } from './utils';

export type LevelOptions =
    | 'Inicial'
    | 'Primaria'
    | 'Secundaria'
    | 'Plan lector';
export type GradeOptions = '1' | '2' | '3' | '4' | '5' | '6';

type GradesContextProps = [
    IGrade[],
    {
        selected: IGrade[];
        selectAll: () => void;
        removeAll: () => void;
        isSelected: (gradeId: string) => boolean;
        toggleSelected: (gradeId: string) => void;
        getGrade: (level: LevelOptions, grade?: GradeOptions) => IGrade;
    },
];

const GradesContext = createContext<GradesContextProps | undefined>(undefined);

interface IGradesProps {
    children: ReactNode;
}

export interface IGrade {
    id: string;
    label: string;
    extendedLabel: string;
    level: LevelOptions;
    grade?: GradeOptions;
}

const grades: IGrade[] = [
    {
        id: uniqueId(),
        label: '2 años',
        extendedLabel: '2 años',
        level: 'Inicial',
        grade: '2',
    },
    {
        id: uniqueId(),
        label: '3 años',
        extendedLabel: '3 años',
        level: 'Inicial',
        grade: '3',
    },
    {
        id: uniqueId(),
        label: '4 años',
        extendedLabel: '4 años',
        level: 'Inicial',
        grade: '4',
    },
    {
        id: uniqueId(),
        label: '5 años',
        extendedLabel: '5 años',
        level: 'Inicial',
        grade: '5',
    },
    {
        id: uniqueId(),
        label: '1ro',
        extendedLabel: '1ro de primaria',
        level: 'Primaria',
        grade: '1',
    },
    {
        id: uniqueId(),
        label: '2do',
        extendedLabel: '2do de primaria',
        level: 'Primaria',
        grade: '2',
    },
    {
        id: uniqueId(),
        label: '3ro',
        extendedLabel: '3ro de primaria',
        level: 'Primaria',
        grade: '3',
    },
    {
        id: uniqueId(),
        label: '4to',
        extendedLabel: '4to de primaria',
        level: 'Primaria',
        grade: '4',
    },
    {
        id: uniqueId(),
        label: '5to',
        extendedLabel: '5to de primaria',
        level: 'Primaria',
        grade: '5',
    },
    {
        id: uniqueId(),
        label: '6to',
        extendedLabel: '6to de primaria',
        level: 'Primaria',
        grade: '6',
    },
    {
        id: uniqueId(),
        label: '1ro',
        extendedLabel: '1ro de secundaria',
        level: 'Secundaria',
        grade: '1',
    },
    {
        id: uniqueId(),
        label: '2do',
        extendedLabel: '2do de secundaria',
        level: 'Secundaria',
        grade: '2',
    },
    {
        id: uniqueId(),
        label: '3ro',
        extendedLabel: '3ro de secundaria',
        level: 'Secundaria',
        grade: '3',
    },
    {
        id: uniqueId(),
        label: '4to',
        extendedLabel: '4to de secundaria',
        level: 'Secundaria',
        grade: '4',
    },
    {
        id: uniqueId(),
        label: '5to',
        extendedLabel: '5to de secundaria',
        level: 'Secundaria',
        grade: '5',
    },
    {
        id: uniqueId(),
        label: 'Plan lector',
        extendedLabel: 'Plan lector',
        level: 'Plan lector',
    },
];

export function GradesProvider({ children }: IGradesProps): JSX.Element {
    const {
        items: selectedGrades,
        selectAll,
        removeAll,
        isSelected,
        toggleSelected,
    } = useSelectedItems(grades);

    const getGrade = useCallback(
        (level: LevelOptions, grade?: GradeOptions): IGrade => {
            const matchLevel = grades.filter((item) => item.level === level);

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return level !== 'Plan lector'
                ? matchLevel.find((item) => item.grade === grade)
                : matchLevel[0];
        },
        [grades],
    );

    return (
        <GradesContext.Provider
            value={[
                grades,
                {
                    selected: selectedGrades,
                    selectAll,
                    removeAll,
                    isSelected,
                    toggleSelected,
                    getGrade,
                },
            ]}
        >
            {children}
        </GradesContext.Provider>
    );
}

export function useGrades(): NonNullable<
    extractGenericOfReactContext<typeof GradesContext>
> {
    const context = useContext(GradesContext);
    if (context === undefined) {
        throw new Error('useGrades must be within a GradesProvider');
    }
    return context;
}
